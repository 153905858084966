/* Custom Zulip icons generated with webfont-loader */

@font-face {
    font-family: 'zulip-icons';
    src: url("files/zulip-icons.d154c8b371c7916361d4.eot?#iefix") format("embedded-opentype"),
url("files/zulip-icons.d154c8b371c7916361d4.woff") format("woff"),
url("files/zulip-icons.d154c8b371c7916361d4.woff2") format("woff2"),
url("files/zulip-icons.d154c8b371c7916361d4.ttf") format("truetype"),
url("files/zulip-icons.d154c8b371c7916361d4.svg#zulip-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

i.zulip-icon {
    font-family: 'zulip-icons' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none;
    font-size: inherit;
    line-height: 100%;

    text-decoration: inherit;
    display: inline-block;
    speak: none;
}

.zulip-icon::before {
    font-family: 'zulip-icons' !important;
    font-style: normal;
    font-weight: normal !important;
    vertical-align: top;
}

.zulip-icon.zulip-icon-arrow-left-circle::before {
    content: "\f101";
}
.zulip-icon.zulip-icon-close::before {
    content: "\f102";
}
.zulip-icon.zulip-icon-search::before {
    content: "\f103";
}
.zulip-icon.zulip-icon-users::before {
    content: "\f104";
}
.zulip-icon.zulip-icon-bot::before {
    content: "\f105";
}
.zulip-icon.zulip-icon-chevron-right::before {
    content: "\f106";
}
.zulip-icon.zulip-icon-edit::before {
    content: "\f107";
}
.zulip-icon.zulip-icon-ellipsis-v-solid::before {
    content: "\f108";
}
.zulip-icon.zulip-icon-exclamation-circle::before {
    content: "\f109";
}
.zulip-icon.zulip-icon-follow::before {
    content: "\f10a";
}
.zulip-icon.zulip-icon-gif::before {
    content: "\f10b";
}
.zulip-icon.zulip-icon-globe::before {
    content: "\f10c";
}
.zulip-icon.zulip-icon-hashtag::before {
    content: "\f10d";
}
.zulip-icon.zulip-icon-inherit::before {
    content: "\f10e";
}
.zulip-icon.zulip-icon-language::before {
    content: "\f10f";
}
.zulip-icon.zulip-icon-lock::before {
    content: "\f110";
}
.zulip-icon.zulip-icon-more-vertical-spread::before {
    content: "\f111";
}
.zulip-icon.zulip-icon-more-vertical::before {
    content: "\f112";
}
.zulip-icon.zulip-icon-move-alt::before {
    content: "\f113";
}
.zulip-icon.zulip-icon-move::before {
    content: "\f114";
}
.zulip-icon.zulip-icon-mute-new::before {
    content: "\f115";
}
.zulip-icon.zulip-icon-mute::before {
    content: "\f116";
}
.zulip-icon.zulip-icon-readreceipts::before {
    content: "\f117";
}
.zulip-icon.zulip-icon-smart-toy::before {
    content: "\f118";
}
.zulip-icon.zulip-icon-smile::before {
    content: "\f119";
}
.zulip-icon.zulip-icon-source-alt::before {
    content: "\f11a";
}
.zulip-icon.zulip-icon-source::before {
    content: "\f11b";
}
.zulip-icon.zulip-icon-star-filled::before {
    content: "\f11c";
}
.zulip-icon.zulip-icon-star::before {
    content: "\f11d";
}
.zulip-icon.zulip-icon-topic::before {
    content: "\f11e";
}
.zulip-icon.zulip-icon-unmute-new::before {
    content: "\f11f";
}
.zulip-icon.zulip-icon-unmute::before {
    content: "\f120";
}
.zulip-icon.zulip-icon-user::before {
    content: "\f121";
}
